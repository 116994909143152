import { Box, Table, TableCell, TableRow, Typography, useTheme } from "@suid/material";
import { t } from "i18next";
import { batch, type Component, createEffect, createSignal, type JSX, Show, type ParentComponent } from "solid-js";
import { Button } from "../components/Button";
import { LoginDialog } from "../components/LoginDialog";
import QrCode from "../components/QrCode";
import { ThrottledButton } from "../components/ThrottledButton";
import { config } from "../config";
import { type Stage, type StageError } from "../domain";
import { ErrorSeverity, ExceptionIdentifier } from "../domain/errors";
import { useInteractionId, useStageData } from "../hooks";
import { ChromeBookHandler } from "../public/ChromeBook.js";
import { getHighestSeverityChallengeException, getStageErrorSeverity } from "../utilities";
import { isChromebook } from "../utilities/UserAgentHelper";

const decoder = new TextDecoder();

/**
 * Base64 decode to string
 *
 * @param base64 String to decode
 *
 * @returns string
 */
function base64URLDecode(base64: string): string {
    // console.log(Uint8Array.from(base64, "base64"));
    try {
        const binString = atob(base64.replace(/-/g, "+").replace(/_/g, "\\"));
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const bytes = Uint8Array.from(binString, (m) => m.codePointAt(0)!);
        return decoder.decode(bytes);
    } catch {
        console.warn("Debug message not properly encoded:", base64);
        return "";
    }
}

/**
 * Lookup error and return translated string.
 *
 * @param error The Error / Exception identifier
 * @returns A translated string represented the error.
 */
function translateError(error?: ExceptionIdentifier): string {
    // List of elaborate error descriptions to show to the user.
    const errorDescriptions: Record<ExceptionIdentifier, string> = {
        // Flow errors
        [ExceptionIdentifier.INTERACTION_UNKNOWN]: t("error.p.no_stage_affiliated"),
        [ExceptionIdentifier.NO_INTERACTION_ID]: t("error.p.no_stage_affiliated"),

        // Backend errors
        [ExceptionIdentifier.PROTOCOL_MISCONFIGURED_CLIENT]: t("error.p.protocol_misconfigured_client"),
        [ExceptionIdentifier.INVALID_SAML_CLIENT]: t("error.p.invalid_saml_client"),
        [ExceptionIdentifier.PROTOCOL_FINALIZE_FAILURE]: t("error.p.protocol_finalize_failure"),
        [ExceptionIdentifier.LOGIN_FAILURE]: t("error.p.login_failure"),
        [ExceptionIdentifier.LOGOUT_FAILURE]: t("error.p.logout_failure"),
        [ExceptionIdentifier.AUTH_SOURCE_LOGIN_FAILURE]: t("error.p.auth_source_login_failure"),
        [ExceptionIdentifier.AUTH_SOURCE_USER_ABORTED]: t("error.p.auth_source_user_aborted"),
        [ExceptionIdentifier.AUTH_SOURCE_MISCONFIGURED]: t("error.p.auth_source_misconfigured"),
        [ExceptionIdentifier.SP_NO_IDENTIFIER]: t("error.p.sp_no_identifier"),
        [ExceptionIdentifier.ABORT_ERROR]: t("error.p.request_aborted"), // Fetch abort error

        // TODO: Write proper description and move these messages to `getErrorDescription()`
        [ExceptionIdentifier.INVALID_CREDENTIALS]: t("error.desc.invalid_credentials"),
        [ExceptionIdentifier.AUTHENTICATION_REQUIRED]: t("error.input.password_required"), // Note that this is not strictly correct.
        [ExceptionIdentifier.INVALID_INPUT]: t("error.desc.invalid_input"),
        [ExceptionIdentifier.HTTP_429]: t("error.desc.rate_limited"),

        // TODO: properly translate
        [ExceptionIdentifier.INVALID_STATE]: t("error.desc.sign_in_failed"), // Invalid state
        [ExceptionIdentifier.HTTP_409]: t("error.desc.sign_in_failed"), // Conflict/Mismatch
        [ExceptionIdentifier.HTTP_422]: t("error.desc.sign_in_failed"), // Unprocessable

        [ExceptionIdentifier.HTTP_500]: t("error.desc.sign_in_failed"), // Server error
        [ExceptionIdentifier.HTTP_502]: t("error.desc.sign_in_failed"), // Bad gateway
        [ExceptionIdentifier.HTTP_503]: t("error.desc.sign_in_failed"), // Service unavailable
        [ExceptionIdentifier.HTTP_504]: t("error.desc.sign_in_failed"), // Gateway timeout
        [ExceptionIdentifier.NETWORK_ERROR]: t("error.desc.sign_in_failed"), // There was a problem with retrieving data from the server; you might have network problems.
        [ExceptionIdentifier.JSON_ERROR]: t("error.desc.sign_in_failed"), // The data from the server was not understood. Please contact the helpdesk if the problem persists.
        [ExceptionIdentifier.UNKNOWN]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.HTTP_400]: t("error.desc.sign_in_failed"), // Bad request
        [ExceptionIdentifier.HTTP_401]: t("error.desc.sign_in_failed"), // Unauthorized
        [ExceptionIdentifier.HTTP_403]: t("error.desc.sign_in_failed"), // Forbidden
        [ExceptionIdentifier.HTTP_404]: t("error.desc.sign_in_failed"), // Not found
        [ExceptionIdentifier.CONFIRMATION]: t("error.desc.sign_in_failed"), // Show the user the account has been changed or created.
        [ExceptionIdentifier.LEGACY_JSON_ERROR_THROWN]: t("error.desc.sign_in_failed"),
    };

    if (error && error in errorDescriptions) return errorDescriptions[error];
    return t("error.desc.something_went_wrong");
}

/**
 * Lookup error and return translated string.
 *
 * @param error The Error / Exception identifier
 * @returns A translated string represented the error.
 */
function getErrorDescription(error?: ExceptionIdentifier): string {
    // List of error short descriptions to show to the user.
    const errorTitles: Record<ExceptionIdentifier, string> = {
        // Flow errors
        [ExceptionIdentifier.INTERACTION_UNKNOWN]: t("error.desc.sign_in_expired"),
        [ExceptionIdentifier.NO_INTERACTION_ID]: t("error.desc.sign_in_expired"),

        // Backend errors
        [ExceptionIdentifier.PROTOCOL_MISCONFIGURED_CLIENT]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.INVALID_SAML_CLIENT]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.PROTOCOL_FINALIZE_FAILURE]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.LOGIN_FAILURE]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.LOGOUT_FAILURE]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.AUTH_SOURCE_LOGIN_FAILURE]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.AUTH_SOURCE_USER_ABORTED]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.AUTH_SOURCE_MISCONFIGURED]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.SP_NO_IDENTIFIER]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.ABORT_ERROR]: t("error.desc.request_aborted"), // Fetch abort error

        // TODO: properly translate
        [ExceptionIdentifier.INVALID_CREDENTIALS]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.AUTHENTICATION_REQUIRED]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.INVALID_INPUT]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.HTTP_429]: t("error.desc.sign_in_failed"),

        [ExceptionIdentifier.INVALID_STATE]: t("error.desc.sign_in_failed"), // Invalid state
        [ExceptionIdentifier.HTTP_409]: t("error.desc.sign_in_failed"), // Conflict/Mismatch
        [ExceptionIdentifier.HTTP_422]: t("error.desc.sign_in_failed"), // Unprocessable

        [ExceptionIdentifier.HTTP_500]: t("error.desc.sign_in_failed"), // Server error
        [ExceptionIdentifier.HTTP_502]: t("error.desc.sign_in_failed"), // Bad gateway
        [ExceptionIdentifier.HTTP_503]: t("error.desc.sign_in_failed"), // Service unavailable
        [ExceptionIdentifier.HTTP_504]: t("error.desc.sign_in_failed"), // Gateway timeout
        [ExceptionIdentifier.NETWORK_ERROR]: t("error.desc.sign_in_failed"), // There was a problem with retrieving data from the server; you might have network problems.
        [ExceptionIdentifier.JSON_ERROR]: t("error.desc.sign_in_failed"), // The data from the server was not understood. Please contact the helpdesk if the problem persists.
        [ExceptionIdentifier.UNKNOWN]: t("error.desc.sign_in_failed"),
        [ExceptionIdentifier.HTTP_400]: t("error.desc.sign_in_failed"), // Bad request
        [ExceptionIdentifier.HTTP_401]: t("error.desc.sign_in_failed"), // Unauthorized
        [ExceptionIdentifier.HTTP_403]: t("error.desc.sign_in_failed"), // Forbidden
        [ExceptionIdentifier.HTTP_404]: t("error.desc.sign_in_failed"), // Not found
        [ExceptionIdentifier.CONFIRMATION]: t("error.desc.sign_in_failed"), // Show the user the account has been changed or created.
        [ExceptionIdentifier.LEGACY_JSON_ERROR_THROWN]: t("error.desc.sign_in_failed"),
    };

    if (error && error in errorTitles) return errorTitles[error];
    return t("error.desc.something_went_wrong");
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- error can be anything.
export const Error: Component<{ stageData?: StageError | Stage; exception?: any }> = (props) => {
    const theme = useTheme();
    const { refetch, resetState } = useStageData();
    const [throttleTime, setThrottleTime] = createSignal(1);
    const [severity, setSeverity] = createSignal(ErrorSeverity.NONE); // Defines the types of buttons
    const [description, setDescription] = createSignal<JSX.Element>(); // Defines the description ("subtitle")
    const [error, setError] = createSignal<ExceptionIdentifier | undefined>();
    const [errorMessage, setErrorMessage] = createSignal<string | undefined>();
    const [reason, setReason] = createSignal<string | undefined>();
    const [correlationId, setCorrelationId] = createSignal<string | undefined>();
    const [debug, setDebug] = createSignal<string | undefined>();

    const ReducedPaddingTableCell: ParentComponent = (cellProps) => <TableCell
        sx={{ padding: theme.spacing(1) }}>{cellProps.children}</TableCell>;

    // When the apps page is configured go to the apps page when no session is started.
    if (!useInteractionId().interactionId() &&
        !!config.app.authority &&
        !!config.app.clientId
    ) {
        const url = new URL(window.location.href);
        // Only handle apps redirect if we didn't come from an error.
        if (url.pathname !== "/error") {
            let redirectUrl: URL = new URL(`${config.baseApi.replace(/\/$/, "")}/apps`);
            // check if it can redirect directly to the special apps domain.
            if (url.pathname === "/apps" && !config.debug.mockResponse) {
                const expectedUrl = new URL(config.app.url ?? config.baseApi);
                if (expectedUrl.origin !== url.origin) {
                    redirectUrl = expectedUrl;
                    if (new URL(config.baseApi).origin === redirectUrl.origin) {
                        redirectUrl.pathname = "/apps";
                    }
                }
            }
            window.location.replace(redirectUrl);
        }
    }

    // Remove interactionId when no flow is triggered.
    createEffect(() => {
        if (props.stageData && (!("type" in props.stageData)) && props.stageData.id === ExceptionIdentifier.INTERACTION_UNKNOWN) {
            const storedInteractionId = sessionStorage.getItem("interactionId");
            if (!storedInteractionId && !ChromeBookHandler.isChromeBookLogin()) {
                window.location.href = "/apps";
            }
            sessionStorage.removeItem("interactionId");
        }

        // Batch all signals since they correspond to a single UI
        batch(() => {
            // Initial error state
            setSeverity(ErrorSeverity.FATAL);
            setReason();
            setCorrelationId();
            setDebug();
            let errorIdentifier: ExceptionIdentifier | undefined;

            if (props.exception || !props.stageData) {
                // Unhandled exception or stand-alone.
                const {
                    error: paramError,
                    reason: paramReason,
                    correlation_id: paramCorrelationId,
                    debug: paramDebug,
                    severity: paramSeverity,
                } = Object.fromEntries(new URLSearchParams(window.location.search));

                setSeverity(ErrorSeverity[paramSeverity as keyof typeof ErrorSeverity] ?? ErrorSeverity.FATAL);
                errorIdentifier = ExceptionIdentifier[paramError as keyof typeof ExceptionIdentifier] ??
                    ExceptionIdentifier.UNKNOWN;
                setReason(paramReason ?? undefined);
                setCorrelationId(
                    (/^[cdefhjkmnprtvwxy2345689]{21}$/.test(paramCorrelationId) ? paramCorrelationId : undefined) ??
                    props.exception?.name ??
                    props.exception,
                );
                if (paramDebug) {
                    setDebug(base64URLDecode(paramDebug));
                } else if (config.debug.verbose) {
                    // In verbose mode, show exception detail.
                    setDebug(
                        props.exception?.stack ??
                        props.exception?.cause ??
                        props.exception?.messge ??
                        props.exception?.name,
                    );
                }

                // Show the error on the console, if present
                if (props.exception) console.error(props.exception);
            } else if ("id" in props.stageData) {
                // StageError
                setSeverity(getStageErrorSeverity(props.stageData));
                errorIdentifier = props.stageData.id;
                setCorrelationId(props.stageData.reference);
            } else if ("exceptions" in props.stageData) {
                // Stage with exceptions
                const exception = getHighestSeverityChallengeException(props.stageData.exceptions);
                setSeverity(getStageErrorSeverity(props.stageData));
                errorIdentifier = exception?.id ?? ExceptionIdentifier.UNKNOWN;
                setCorrelationId(exception?.detail);
            }
            setError(errorIdentifier);
            setDescription(getErrorDescription(errorIdentifier));
            setErrorMessage(translateError(errorIdentifier));
        });
    });

    const primaryAction = (): void => {
        if (severity() === ErrorSeverity.FATAL) {
            // Just go back to the apps page.
            window.location.href = "/apps";
        } else {
            // Increase throttle time and retry.
            setThrottleTime(throttleTime() * 2);
            refetch();
        }
    };

    const primaryActionLabel = (): string => {
        switch (severity()) {
            case ErrorSeverity.NONE:
            case ErrorSeverity.DEBUG:
            case ErrorSeverity.INFO:
                return t("general.btn.continue");

            case ErrorSeverity.WARNING:
            case ErrorSeverity.ERROR:
                return t("general.btn.retry");

            case ErrorSeverity.FATAL:
            default:
                return t("general.btn.back_to_app_overview");
        }
    };

    return (<LoginDialog
        minContentWidth
        id="Error"
        title={t("error.title.whoops")}
        description={description()}
        fullscreen={isChromebook()}
        minContentHeight={isChromebook()}
        orientation={isChromebook() ? "horizontal" : undefined}
        fullHeight={isChromebook()}
        fullWidth
        centerContent
        secondaryButton={severity() !== ErrorSeverity.FATAL && <Button
            data-testid="BackButton"
            color="secondary"
            variant="contained"
            onClick={() => void resetState()}
            sx={theme.mixins.button}
        >
            {t("general.btn.back")}
        </Button>}
        primaryButton={<Show when={!(severity() === ErrorSeverity.FATAL && ChromeBookHandler.isChromeBookLogin())}>
            <ThrottledButton
                disableTime={throttleTime()}
                onClick={primaryAction}
                variant="contained"
                color="secondary"
            >
                {primaryActionLabel()}
            </ThrottledButton>
        </Show>}
        errors={[]}
    >
        <Typography variant="body1" sx={theme.mixins.typography} gutterBottom role="alert">
            {errorMessage() && <Box sx={{ pt: 2, mb: 3 }}>
                {errorMessage()}
            </Box>}

            <Box
                sx={{ mt: 2, pb: 3 }}
                displayRaw="flex"
                justifyContent="space-between"
                gap={2}
                alignItems="center"
                flexWrap="wrap-reverse"
            >
                <Table style={{ "flex": 2, "min-width": "280px" }}>
                    {error() && <TableRow>
                        <ReducedPaddingTableCell>{t("error.p.code")}</ReducedPaddingTableCell>
                        <ReducedPaddingTableCell>
                            <Typography data-testid="ErrorId" sx={theme.mixins.typography} variant="body2">{error()}</Typography>
                        </ReducedPaddingTableCell>
                    </TableRow>}
                    {reason() && <TableRow>
                        <ReducedPaddingTableCell>{t("error.p.code_identifier")}</ReducedPaddingTableCell>
                        <ReducedPaddingTableCell>
                            <Typography data-testid="ErrorReason" sx={theme.mixins.typography} variant="body2">{reason()}</Typography>
                        </ReducedPaddingTableCell>
                    </TableRow>}
                    {correlationId() && <TableRow>
                        <ReducedPaddingTableCell>{t("error.p.correlation_id")}</ReducedPaddingTableCell>
                        <ReducedPaddingTableCell>
                            <Typography data-testid="ErrorCorrelation" sx={theme.mixins.typography} variant="body2">{correlationId()}</Typography>
                        </ReducedPaddingTableCell>
                    </TableRow>}
                </Table>
                {correlationId() && <QrCode
                    data-testid="QrImage"
                    data={correlationId()}
                    options={{ image: undefined }}
                    style={{ "flex": 1, "margin": "auto", "max-height": "160px" }}
                />}
            </Box>

            {debug() && (
                <Typography sx={{ ...theme.mixins.typography }} data-testid="Url" variant="inherit" class="code">
                    {debug()}
                </Typography>)}
        </Typography>
    </LoginDialog>);
};

export default Error;
